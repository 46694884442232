import { PageProps } from 'gatsby';
import { NoteData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Note';
import List from '~/containers/Espace/List';
import note from '~/params/note.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useNotes from '~/utils/useNotes';

const PageEspaceNotes: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({ espace, location, pageContext: { tab }, user }) => {
  const { t } = useTranslation();
  const items = useNotes({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <List
        itemList={Item}
        model={
          new NoteData({
            espaceId: espace.id,
            params: note,
            wheres: {
              parent: '',
            },
          })
        }
        search={location.search}
        subMenu={items}
        tabIndex={tab ?? 0}
        title={t('notes.title')}
        user={user}
      />
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceNotes);
